function Recruit() {
  return (
    <section className="hom-company" id="recruit">
        <div className="l-container">
            <h2 className="c-h2 fadein active" title="RECRUIT">採用情報</h2>
            <div className="hom-serviceList fadein active">
            　まだ歴史の浅い企業です。これから先、「楽しいこと」「嬉しいこと」「つらいこと」、多くのイベントが待ち構えています。<br/>
            　大変だと思うときもあるかもしれません。ですが、小さい企業だからこそできることが多くあります。<br/>
            　お客様のために働くことは、結果として自分のためにもなると私たちは考えています。<br/>
            　私たちは目標を持ってまっすぐ向かっていける人と一緒に仕事をしたいと思っています。<br/>
            　こちらに共感いただけるならば、一緒にチャンスを掴んで成長しましょう。<br/>
                <br/>
            　ご連絡はお問合せフォームよりお願いいたします。<br/><br/><br/>
            </div>
        </div>
    </section>
  );
}
export default Recruit;