import './style.css';
import logo from './img/logo.svg';
import { Link } from 'react-scroll';

function Header() {
  return (
    <div className="Header l-header">
      <div className="l-headerInner">
        <div className="l-header__logo">
          <a href="https://pitatto.co.jp/"><img src={logo} alt="Pitatto" width="70" style={{marginTop:`70px`}} /></a>
        </div>
        <div className="l-header__nav">
          <nav>
            <ul>
              <li><Link to="message" smooth={true} style={{lineHeight:`20px`}}>メッセージ<br/><font style={{fontSize:`12px`}}>Message</font></Link></li>
              <li><Link to="service" smooth={true} style={{lineHeight:`20px`}}>事業内容<br/><font style={{fontSize:`12px`}}>Service</font></Link></li>
              <li><Link to="company" smooth={true} style={{lineHeight:`20px`}}>会社情報<br/><font style={{fontSize:`12px`}}>Company</font></Link></li>
              <li><Link to="access"  smooth={true} style={{lineHeight:`20px`}}>アクセス<br/><font style={{fontSize:`12px`}}>Access</font></Link></li>
              <li><Link to="recruit" smooth={true} style={{lineHeight:`20px`}}>採用情報<br/><font style={{fontSize:`12px`}}>Recruit</font></Link></li>
              <li><Link to="contact" smooth={true} style={{lineHeight:`20px`}}className="c-btn--point c-btn--auto">お問い合わせ<br/><font style={{fontSize:`12px`}}>Contact</font></Link></li>
            </ul>
          </nav>
        </div>
        <div className="l-header__bar"><span></span><span></span><span></span></div>
      </div>
    </div>
  );
}
export default Header;