function Access() {
  return (
    <section className="hom-company" id="access">
        <div className="l-container">
            <h2 className="c-h2 fadein active" title="ACCESS">アクセス</h2>
            <div className="hom-serviceList fadein active">
                <iframe title="地図" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3238.737591516902!2d139.69776371526015!3d35.732671580182405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d57defe52ed%3A0xf8b793d376728ceb!2z44CSMTcxLTAwMjEg5p2x5Lqs6YO96LGK5bO25Yy66KW_5rGg6KKL77yV5LiB55uu77yS77yW4oiS77yR77yWIO-8o--8qO-8qe-8ou-8oeODk-ODqw!5e0!3m2!1sja!2sjp!4v1593828508205!5m2!1sja!2sjp" width="100%" height="500" frameBorder="0" style={{border:`0px`}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
            </div>
        </div>
    </section>
  );
}
export default Access;