import logo from './img/logo.svg';

function Footer() {
  return (
    <div className="l-footer fadein active">
        <div className="l-container">
            <div className="l-footer__logo"><img src={logo} alt="Pitatto" width="70"/></div>
            <p className="l-footer__copyright">© Pitatto CO.,LTD 2018</p>
        </div>
    </div>
  );
}
export default Footer;