import back03 from './img/back03.svg';
import back04 from './img/back04.svg';
import service1 from './img/service1.png';
import service2 from './img/service2.png';
import service3 from './img/service3.png';
import service4 from './img/service4.png';

function Message() {
  return (
    <section className="hom-features">
        <div className="hom-shape"><img src={back03} alt=""/></div>
        <div className="hom-featuresInner">
            <div className="l-container fadein active" id="service">
                <h2 className="c-h2" title="SERVICE">事業内容</h2>
                <p className="c-readcopy">Pitattoは「何をしたらいいのかわからない」というご担当者様も含め、一から寄り添ってサポート致します。</p>
                <div className="hom-serviceList">
                    <ul>
                        <li className="js-anime is-animate fadein active">
                            <div className="hom-serviceList__icon"><img src={service1} alt="要件定義／業務分析"/></div>
                            <h3 title="ANALYZE">要件定義／業務分析</h3>
                            <p>ご担当者様自身がどのように進めていくべきかイメージできていないケースを多く見てきました。私たちはそんなご担当者様の立場に変わってお客様の業務分析から取り組み、最適なシステムをご提案致します。</p>
                        </li>
                        <li className="js-anime is-animate fadein active">
                            <div className="hom-serviceList__icon"><img src={service2} alt="デザイン制作／システム開発"/></div>
                            <h3 title="CREATIVE">デザイン製作／システム開発</h3>
                            <p>弊社ではデザインも含めご提案させていただきます。ご担当者様がデザイン、インフラ、開発などを別々に発注するといった負担がないよう一環して経験豊富なエンジニアがサポート致します。</p>
                        </li>
                        <li className="js-anime is-animate fadein active">
                            <div className="hom-serviceList__icon"><img src={service3} alt="運営支援／保守サポート"/></div>
                            <h3 title="SUPPORT">運用支援／保守サポート</h3>
                            <p>システム開発はゴールではありません。モノだけお届けするのではなく、お届けしたシステムとお客様が共に歩み初めてこそ、システム開発と考え全力でサポート致します。</p>
                        </li>
                        <li className="js-anime is-animate fadein active">
                            <div className="hom-serviceList__icon"><img src={service4} alt="SES事業"/></div>
                            <h3 title="VISIT">SES事業</h3>
                            <p>お客様のご要望によっては、弊社のエンジニアが常駐する形で開発をお手伝いすることも可能です。状況に応じて柔軟にサポート致します。</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="hom-shape"><img src={back04} alt=""/></div>
    </section>
  );
}
export default Message;