function Company() {
  return (
    <section className="hom-company" id="company">
        <div className="l-container">
            <h2 className="c-h2 fadein active" title="COMPANY">会社情報</h2>
            <table className="c-tb c-tb--block fadein active">
                <tbody>
                    <tr>
                        <th>会社名</th>
                        <td>株式会社Pitatto</td>
                    </tr>
                    <tr>
                        <th>創業</th>
                        <td>2018年12月18日</td>
                    </tr>
                    <tr>
                        <th>代表者</th>
                        <td>代表取締役 松原 慧伍</td>
                    </tr>
                    <tr>
                        <th>資本金</th>
                        <td>500万円</td>
                    </tr>
                    <tr>
                        <th>事業内容</th>
                        <td>
                        <ul style={{margin:`0px`}}>
                            <li>Webシステム開発（業務システム、ECサイト等）</li>
                            <li>Shopifyアプリ開発、ストア構築</li>
                            <li>ホームページ製作（デザイン設計、ドメイン取得、サーバー運用保守）</li>
                            <li>システム多言語化対応（英語、ベトナム語、ネパール語、中国語等）</li>
                            <li>SEO対策、Webマーケティング</li>
                            <li>スパム対策、テレワーク事業支援、運用サポート等</li>
                        </ul>
                        </td>
                    </tr>
                    <tr>
                        <th>所在地</th>
                        <td>〒171-0021 東京都豊島区西池袋5丁目26-16 CHIBAビル</td>
                    </tr>
                    <tr>
                        <th>取引銀行</th>
                        <td>三井住友銀行　所沢支店</td>
                    </tr>
                    <tr>
                        <th>お問合せメールアドレス</th>
                        <td>contact@pitatto.co.jp</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
  );
}
export default Company;