import main from './img/mainback.png';
import back01 from './img/back01.svg';
import back02 from './img/back02.svg';

function Main() {
  return (
    <div className="hom-mv">
        <div className="l-container">
            <div className="hom-mvWrap">
                <div className="hom-mv__copy is-animate">
                    <p>継続した提案を<br/>大切にします。</p>
                    <h1>私達はシステム開発をゴールとしません。<br/>全力でお客様をサポートし続けます。</h1>
                </div>
                <div className="hom-mv__illust is-animate">
                    <div><img src={main} alt="継続した提案中..."/></div>
                </div>
            </div>
        </div>
        <div className="hom-mv__shape01"><img src={back01} alt=""/></div>
        <div className="hom-mv__shape02"><img src={back02} alt=""/></div>
    </div>
  );
}
export default Main;