import back08 from './img/back08.svg';
import back09 from './img/back09.svg';
import React from 'react';
import axios from 'axios';

class Contact extends React.Component {

    constructor(props) {
        super(props)
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            name: "",
            company: "",
            tel: "",
            email: "",
            note: ""
        }
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        this.setState({[name]: value })
    }

    handleSubmit(e) {

        e.preventDefault();
        axios.post(
            'https://asia-northeast1-pitatto-web.cloudfunctions.net/sendSlackMessage',
            {
                name:this.state.name,
                company:this.state.company,
                tel:this.state.tel,
                email:this.state.email,
                note:this.state.note,
            }
        )
        .then(function(response){
            alert("お問い合わせありがとうございました。\n担当者より追ってご連絡させていただきます。");
        })
    }

    render() {
        return (
            <section className="hom-contact">
                <div className="hom-shape"><img src={back08} alt="" /></div>
                <div className="hom-contactInner">
                    <div className="l-container fadein active" id="contact">
                        <h2 className="c-h2 fadein active" title="CONTACT">お問い合わせ</h2>
                        <p className="c-readcopy fadein active">ご相談、お見積りは無料です。お気軽にお問い合わせください。</p>
                        <div className="hom-contactForm">
                            <p className="hom-contactForm__title fadein active">お問合せフォーム</p>
                            <form onSubmit={this.handleSubmit} className="h-adr fadein active">
                                <input type="hidden" id="reCAPTCHA_token" name="reCAPTCHA_token" value="03AGdBq259-rume5C6YUDp02O9CVqTYMOoTG1TGSyuCiCpy6hOm7gZCfFfP2l1s9olEA1ywDoSB8ZvlssbLRTlTt-o314cEt702FJVFvzGlB3-YQ3Jet4n1OOjT0dQnKyf2Sx3KimLJ5MnHE1uwvuH1TOxdw8cJfNSSGnZ74KslKiEscsOLzUGieqVTUTKAi75yxTkeyCdhSEL3m1DVhSMfiZz5vwDQLhMImWdf2gA_-loX2uWt75Mzhp8RuJL2pDzzmSZoSx7VPL2LUlDx7fgOgG-cSF8sFTmgn-TaYU1Ud3p6xvi6WmQPdjHj36DFvNB9OUc_n99B8YQaTLYucDxVOJpRYfPdAS0JDT9HQO-tFkaage1QNG_qcK0Gq1Q_9tHc8p0IHXepS7VjgWzklF1ovfsEKM_qDDcnPt2uVZf1Yca803WTBOrAJQLyO-zgDEW8SbV97F0NgGDxV7GMZLFKuPEFAXsUhcEU8Xg67rxIAnst6OcBL_rTas6E3j8fYByR13wlj1fHJ2G" />
                                <dl>
                                    <dt>
                                        <label>お名前</label>
                                        &nbsp;<span className="ex-essential">[必須]</span></dt>
                                    <dd>
                                        <input 
                                            type="text" 
                                            name="name" 
                                            placeholder="例）鈴木 太郎" 
                                            required="required" 
                                            className="c-inp--xl"
                                            onChange={this.handleInputChange}
                                        />
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>
                                        <label htmlFor="company">貴社名</label>
                                        &nbsp;<span className="ex-essential">[必須]</span></dt>
                                    <dd>
                                        <input 
                                            type="text" 
                                            name="company" 
                                            placeholder="例）株式会社Pitatto" 
                                            required="required" 
                                            className="c-inp--xl"
                                            onChange={this.handleInputChange}
                                        />
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>
                                        <label htmlFor="tel">電話番号</label>
                                        &nbsp;<span className="ex-essential">[必須]</span></dt>
                                    <dd>
                                        <input 
                                            type="text"
                                            name="tel"
                                            placeholder="例）03-0000-0000"
                                            required="required"
                                            className="c-inp--xl"
                                            onChange={this.handleInputChange}
                                        />
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>
                                        <label htmlFor="email">メールアドレス</label>
                                        &nbsp;<span className="ex-essential">[必須]</span></dt>
                                    <dd>
                                        <input
                                            type="email"
                                            name="email"
                                            required="required"
                                            placeholder="例）example@example.com"
                                            className="c-inp--xl"
                                            onChange={this.handleInputChange}
                                        />
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>
                                        <label htmlFor="memo">お問い合わせ内容</label>
                                    </dt>
                                    <dd>
                                        <textarea 
                                            name="note"
                                            rows="6"
                                            cols="50"
                                            required="required"
                                            placeholder="お問い合わせ内容をご記入ください。"
                                            onChange={this.handleInputChange}
                                        >
                                        </textarea>
                                    </dd>
                                </dl>
                                <br />
                                <div className="alert2">
                                    本サイトはスパム防止のためreCAPTCHAとGoogleによって保護しています。
                                    <a href="https://policies.google.com/privacy" rel="noreferrer" target="_blank">プライバシーポリシー</a>と<a href="https://policies.google.com/terms" rel="noreferrer" target="_blank">利用規約</a>が適用されます。
                                </div>
                                <div className="u-center">
                                    <button id="btnSubmit" className="c-btn--def c-btn--auto">内容を確認して送信する</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="hom-shape"><img src={back09} alt="" /></div>
            </section>
        )
    }
}
export default Contact;