function Message() {
  return (
    <section className="hom-features" id="message">
	    <div className="l-container">
            <h2 className="c-h2 fadein active" title="Message">メッセージ</h2>
            <p className="c-readcopy fadein active">
                私たちはシステム開発をゴールとしません。<br/>
                モノだけお届けするのではなく、お届けしたシステムとお客様が共に歩み初めてこそ、システム開発と考えサポート致します。
            </p>
            <div className="hom-aboutPurpose fadein active">
                <ul className="js-anime is-animate">
                    <li style={{paddingBottom:`30px`}}>
                        <h3 title="FOR CUSTOMER">お客様との一体感</h3>
                        <p>システム開発はお互い遠慮しあっては良いものはできません。素早い対応や、深く踏み込んだ提案からお客様との信頼関係を築き、一体感のある開発を心がけます。</p>
                    </li>
                    <li style={{paddingBottom:`30px`}}>
                        <h3 title="FOR CLEARLY">できないものは、はっきりNO</h3>
                        <p>システム開発は勢いだけでは成立しません。できないものをはっきりNOと伝え、その上でお客様のご要望を実現する方法を考え提案することが、私たちの本来あるべき姿だと考えています。
                        </p>
                    </li>
                    <li style={{paddingBottom:`30px`}}>
                        <h3 title="FOR FLEXIBLE">柔軟な対応</h3>
                        <p>私たちの会社はまだまだ小さな会社ですが小さいことはデメリットと考えていません。小さい企業だからこそ仕組みやルールに縛られずお客様へ柔軟な対応をご提供致します。</p>
                    </li>
                </ul>
            </div>
            <h3 className="fadein trouble">お悩みがあればまずはご相談ください。</h3>
        </div>
    </section>
  );
}
export default Message;