import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import './index.css';
import Header from './Header';
import Main from './Main';
import Message from './Message';
import Service from './Service';
import Company from './Company';
import Access from './Access';
import Recruit from './Recruit';
import Contact from './Contact';
import Footer from './Footer';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <GoogleReCaptchaProvider
    reCaptchaKey="6LfFp64ZAAAAAFuW7pB3DIDCdQ5KlXPFAKrw7aW1"
    language="japanese"
    scriptProps={{
      async: false, // optional, default to false,
      defer: false, // optional, default to false
      appendTo: "head", // optional, default to "head", can be "head" or "body",
      nonce: undefined // optional, default undefined
    }}
  >
    <Header />
    <Main />
    <Message />
    <Service />
    <Company />
    <Access />
    <Recruit />
    <Contact />
    <Footer />
  </GoogleReCaptchaProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
